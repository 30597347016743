@import '../../../styles/base/vars';

.rating {
  text-align: right;

  span {
    display: block;
    padding: 0 5px;
  }
  
  svg {
    width: 30px;
    height: auto;
  }
}

@media only screen and (max-width:990px) {
  .rating {

  }
}