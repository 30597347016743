@import '../../../styles/base/vars';

.card-report {
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  display: flex;
  margin-bottom: 40px;

  .result {
    width: 43%;
    background: #FBFBFB;
    padding: 25px 28px;

    h4 {
      color: $orange;
      font-size: 24px;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: -0.02em;
      margin: 0 0 20px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }

  .research {
    width: 57%;

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;

      strong {
        font-weight: 600;
      }
    }

    .identification {
      padding: 25px;
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #E8E8E8;

      p {
        width: 60%;
        margin-bottom: 0;
      }

      .classification {
        width: 40%;
      }
    }

    .geral {
      display: flex;
      flex-wrap: wrap;
      padding: 25px;

      p {
        width: 55%;

        &:first-child {
          width: 100%;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      .rating {
        width: 45%;
        display: flex;
        justify-content: flex-end;
      }
    }

  }
}

@media only screen and (max-width:990px) {
  .card-report {
    display: block;

    .result {
      width: 100%;
    }

    .research {
      width: 100%;

      .identification, .geral {
        display: block;

        p {
          width: 100%;
          margin-bottom: 20px !important;
        }

        .classification, .rating {
          width: 100%;
          justify-content: flex-start;
        }
      }
    }
  }
}

@media only screen and (max-width:400px) {
  .card-report {
    .result {
      h4 {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}