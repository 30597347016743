@import '../../../styles/base/vars';

.assessment-home {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  background-color: #ffffff;

  .logo-frst {
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -610px;
    z-index: 10;
  }

  .download {
    text-align: center;
    margin-bottom: 90px;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      text-align: center;
    }

    button {
      text-align: center;
      padding: 14px 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #ffffff;
      border-radius: 4px;
      background-color: $orange;
      transform-origin: center;
      transition: all 0.15s ease-out;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

@media only screen and (max-width:990px) {
  .assessment-home {
    .logo-frst {
      margin-left: -77px;
    }
    .download {
      p {
        padding: 0 15px;
      }
    }
  }
}