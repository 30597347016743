h1 {
  font-family: "Work Sans";
  font-style: normal;
  font-size: 52px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
}

h3 {
  font-family: "Work Sans";
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: #000000;
}

h4 {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 21px;
  text-align: center;
  color: #ff8a15;
}

.container-report {
  padding: 45px 88px;
  background: #f2f2f2;
}

@media screen and (max-width:1000px) {

  .container-report {
    padding: 45px 10px;
    background: #f2f2f2;
  }
  .content-skills {
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #bdbdbd;
    padding: 48px 0px !important;
    margin-top: 24px;
  }



.header-content {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.content-skills {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  padding: 48px 24px;
  margin-top: 24px;
}

.report-date {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.5rem;
  margin-right: 36px;

  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  font-size: 20px;
  color: #757575;
  padding-right: 1.5rem;
}

.skills-description {
  border-bottom: 1px solid #bdbdbd;
  padding: 0rem 1.5rem;
}

.buttonDowload {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;
  width: 241px;
  height: 48px;
  background: #ff4d0d;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: 20px auto;
}

.buttonDowloadText {
  align-items: center;
  margin: 0;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.containerButton {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0rem;
  align-items: flex-end;
  text-align: center;
}

.containerButtonText {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  text-align: center;
  margin: auto;
}

.containerExperience {
  width: 100%;
  height: 30rem;
  display: contents !important;
  padding: 2.5rem;
  flex-direction: column;
  background-color: yellowgreen;
}

.experienceTitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 10px 0 2rem 1rem !important;
}

.experienceDescription {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  margin: 0 0 1rem 1rem !important;
}

.experienceForm {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.experienceFormTag {
  width: 100%;
  padding: 0 !important;
  font-size: 16px;
}

.experienceFormTag > p{
  width: 100%;
  padding: 15px !important;
  font-size: 16px;
}

.experienceFormTextarea {
  width: 100%;
  padding: 0.9rem !important;
  font-size: 16px;
}

.formTextarea {
  background: #ebebeb;
  height: 11rem;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  outline: none;
}

.formTags {
  max-width: 600px;
  height: auto;
  padding: 0rem 0rem 1rem 0.5rem !important;
  display: flex;
  flex-wrap: wrap;
}

.itemTag {
  background: #450070;
  border-radius: 40px;
  width: fit-content;
  padding: 1.2rem 2rem;
  margin-bottom: 0;
  margin: 0.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.itemTagText {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
}

.experienceConteinerButton {
  display: flex;
  padding: 1rem 1rem !important;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.experienceButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px !important;
  height: 48px;
  background: #ff4d0d;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  color: #ffff;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  cursor: pointer;
  padding: 0 1rem;
}


  
}

.header-content {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.content-skills {
  position: relative;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  padding: 48px 24px;
  margin-top: 24px;
}

.report-date {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1.5rem;
  margin-right: 36px;

  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  font-size: 20px;
  color: #757575;
  padding-right: 1.5rem;
}

.skills-description {
  border-bottom: 1px solid #bdbdbd;
  padding: 0rem 1.5rem;
}

.buttonDowload {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 16px;
  gap: 8px;
  width: 241px;
  height: 48px;
  background: #ff4d0d;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.buttonDowloadText {
  align-items: center;
  margin: 0;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.containerButton {
  display: flex;
  flex-direction: column;
  margin: 2.5rem 0rem;
  align-items: flex-end;
}

.containerButtonText {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
}

.containerExperience {
  width: 100%;
  height: 30rem;
  display: flex;
  padding: 2.5rem;
  flex-direction: column;
}

.experienceTitle {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 130%;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01em;
  color: #000000;
  margin: 0 0 2rem 2rem;
}

.experienceDescription {
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  color: #000000;
  margin: 0 0 1rem 2rem;
}

.experienceForm {
  padding: 1.5rem;
  display: flex;
}

.experienceFormTag {
  width: 100%;
  padding: 0.5rem;
  font-size: 16px;
}

.experienceFormTextarea {
  width: 100%;
  padding: 0.5rem;
  font-size: 16px;
}

.formTextarea {
  background: #ebebeb;
  height: 11rem;
  border: 1px solid #bdbdbd;
  border-radius: 5px;
  width: 100%;
  padding: 0.5rem;
  outline: none;
}

.formTags {
  max-width: 600px;
  height: auto;
  padding: 0rem 0rem 1rem 0rem;
  display: flex;
  flex-wrap: wrap;
}

.itemTag {
  background: #450070;
  border-radius: 40px;
  width: fit-content;
  padding: 1.2rem 2rem;
  margin-bottom: 0;
  margin: 0.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.itemTagText {
  display: flex;
  align-items: center;
  margin: 0;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  cursor: pointer;
}

.experienceConteinerButton {
  display: flex;
  padding: 1rem 2rem;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.experienceButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 48px;
  background: #ff4d0d;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  color: #ffff;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  cursor: pointer;
  padding: 0 1rem;
}

.toastGreen {
  width: 100%;
  background: #d1f6d1;
  border: solid #1ba853;
  border-width: 1px 1px 1px 5px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.toastGreen svg {
  width: 1.5rem;
  height: 1.5rem;
}

.toastContainer {
  position: fixed;
  top: 95vh;
  width: 85%;
}

.toastRed {
  width: 100%;
  background: #ffe0e0;
  border: solid #c00f00;
  border-width: 1px 1px 1px 5px;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
}

.toastRed svg {
  width: 1.5rem;
  height: 1.5rem;
}

.toastText {
  padding-left: 1rem;
  margin: 0;
  flex: 1;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
}

.toastButton {
  display: flex;
  background: transparent;
  border: none;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.toastButton svg {
  width: 0.7rem;
  height: 0.7rem;
  cursor: pointer;
}

.textRelatorio span p {
  font-size: 18px;
  color: #000000;
}

.containerAntigoAssessment {
  width: 100%;
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  margin-top: 8rem;
}

.containerAntigoAssessment p {
  width: 22rem;
  font-family: "PT Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 110%;
  text-align: right;
  color: #000000;
  margin-right: 2rem;
}

.containerAntigoAssessment button {
  width: 18rem;
}

.containerMenuLateral {
  width: 45rem;
}

@media screen and (max-width: 1120px) {
  .header-content {
    flex-direction: column;
  }
  .containerMenuLateral {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textRelatorio {
    width: 100%;
    margin-top: 2rem;
  }
}

@media (max-width: 1366px) {
  h1 {
    font-size: 32px;
  }
  h3 {
    font-size: 22px;
  }

  .textRelatorio span p {
    font-size: 15px;
  }

  .containerAntigoAssessment {
    margin-top: 3rem;
  }

  .containerAntigoAssessment p {
    font-size: 15px;
  }

  .report-date {
    font-size: 18px;
  }
}

