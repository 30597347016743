.essay-wrapper {
    min-height: calc(100vh - 160px);
    margin: 0px;
    padding: 50px;
    background-color: #fff;
}

.essay-wrapper .main-container {
    max-width: 1200px;
    margin: 0px auto;
}

.essay-wrapper .main-container-inner {
    padding-right: 2rem;
    padding-left: 2rem;
}

.questions-wrapper .title {
    font-weight: 900;
    font-size: 2.3rem;
    line-height: 1;
    color: #2a3d41;
    margin: 0 0 20px;
    font-family: 'Work Sans', sans-serif;
}

input {
    max-width: 90vw;
}

.btn-black:disabled {
    cursor: not-allowed;
    background: rgb(245, 245, 245);
    color: rgb(184, 184, 184);
}


.sucessTitle {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.2;
    text-align: center;
    margin: auto;
    padding: 0 0 50px;
    color: rgba(0,0,0,.85);
}

.contentLoading {
    display: flex;
    justify-content: center;
    align-items: center; 
    height: 300px;
    flex-wrap: wrap;
    background-color: white !important;
  }
  
  .contentLoading h3 {
    width: 100%;
    text-align: center;
    margin: 0;
    padding: 0;
  }
  
  
  .loading {
    width: 250px; 
    height: 25px; 
    margin: 10px auto;
  
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 800px;
    position: relative;
    color: transparent !important;
    user-select: none; 
    
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-name: placeholderShimmer;
    -webkit-animation-timing-function: linear;
  }
  
  @keyframes placeholderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0; 
    }
  }
  
  .select-shimmer {
    border: 1px solid hsl(0, 0%, 80%);
    border-radius: 5px;
  }

  .loader {
    display: inline-flex;

    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #F35F24; /* Blue */
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin-right: 4px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }