@import '../../../styles/base/vars';

.classification {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  li {
    margin: 0 3px;
    width: 38px;
    height: 38px;

    button {
      display: block;

      svg {
        pointer-events: none;
        &:last-child {
          display: none;
        }
      }

      &:hover, &.current {
        svg {
          &:first-child {
            display: none;
          }
          &:last-child {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (max-width:400px) {
  .classification {
    li {
      margin: 0;
    }
  }
}