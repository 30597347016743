.contentLoading {
  display: flex;
  justify-content: center;
  align-items: center; 
  height: 300px;
  flex-wrap: wrap;
  background-color: white !important;
}

.contentLoading h3 {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
}


.loading {
  width: 250px; 
  height: 25px; 
  margin: 10px auto;

  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 800px;
  position: relative;
  color: transparent !important;
  user-select: none; 
  
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  0% {
      background-position: -468px 0;
  }
  100% {
      background-position: 468px 0; 
  }
}

.select-shimmer {
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
}