ul li {
  margin-top: 13px;
}

.listStandard {
  font-size: 15px;
  font-weight: 400;
  color: #2a3d41;
  max-width: 75%;
  font-family: "Work Sans", sans-serif;
}

.listStandard > li {
  vertical-align: middle;
  list-style: disc;
  margin-bottom: 13px;
}

.containerButtons {
  margin: 20px 0 20px 0;
}

.assessmentExpireAtText {
  font-size: 15px;
  font-weight: 400;
  color: #2a3d41;
}

.howMethodText {
  margin-top: 40px;
  font-size: 16px;
  line-height: 1.2;
  /* text-transform: uppercase; */
}

.btn-outline,
.btn-outline:hover {
  border: 1px solid #f35f24;
  background: transparent;
  color: #f35f24;
}
