.home-title {
  font-size: 48px;
  padding: 50px 0 0;
  font-weight: 700;
}

.description-head {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 16px;
  color: #222;
  font-family: Work Sans, sans-serif;
  text-align: center;
}
