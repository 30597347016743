.skill-board {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 1.5rem;
  gap: 16px;
}

@media screen and (max-width:700px) {
  .skill-board {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 1rem;
    gap: 16px;
  }
}

.skill-rating {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.skill-rating-description {
  font-size: 18px;
  font-weight: 400;
  color: #444444;
  width: 50%;
  line-height: 110%;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.rating-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  color: #222222;
}
.skill-bar {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 8px;
  gap: 28px;
  font-size: 14px;
}

@media (max-width: 1366px) {
  .skill-rating-description {
    font-size: 16px;
  }
}

@media screen and (max-width:1000px) {
  .skill-board {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem 1rem;
    gap: 16px;
  }

  .skill-rating {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .skill-rating-description {
    font-size: 18px;
    font-weight: 400;
    color: #444444;
    width: 100%;
    line-height: 110%;
  }
  
  .rating {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
    padding: 10px;
  }
  
  .rating-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #222222;
  }
  .skill-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 8px;
    gap: 28px;
    font-size: 14px;
  }

  .container-bar{
    display: block !important;
    width: 100%;
    flex-direction: column;
  }
  
}


