@import '../../styles/base/vars';

.how-to {
  position: relative;
  background-color: $orange;
  color: $yellow;
  height: 800px;
  padding-top: 40px;

  h4 {
    width: 100%;
    color: $orange;
    font-size: 28px;
    text-align: center;
  }

  p {
    width: 100%;
    color: #000000;
    text-align: center;

    strong {
      font-weight: 600;
    }
  }

  &>button {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: $yellow;
    top: 50%;
    left: 50%;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $orange;
    font-size: 36px;
    font-weight: 200;
    transition: all 0.1s ease-in-out;
    transform-origin: center;
    z-index: 10;

    &:hover, &.active {
      transform: scale(1.1);
      font-weight: 400;
    }

    &.btn1{ margin-top: -246px; margin-left: -13px; }
    &.btn2{ margin-top: -103px; margin-left: 240px; }
    &.btn3{ margin-top: 190px; margin-left: 235px;  }
    &.btn4{ margin-top: 192px; margin-left: -268px; }
    &.btn5{ margin-top: -101px; margin-left: -268px;}
  }

  &.activate1 {
    &>button {
      &.btn1 { transform: scale(1.1); font-weight: 400; }
      &:not(.btn1) { transform: scale(1); font-weight: 200; }
    }
    .capsule {
      li {
        &.slide1 { transform: scale(1); opacity: 1; transition-delay: 0.2s; }
        &:not(.slide1) { transform: scale(0); opacity: 0; }
      }
    }
  }

  &.activate2 {
    &>button {
      &.btn2 { transform: scale(1.1); font-weight: 400; }
      &:not(.btn2) { transform: scale(1); font-weight: 200; }
    }
    .capsule {
      li {
        &.slide2 { transform: scale(1); opacity: 1; transition-delay: 0.2s; }
        &:not(.slide2) { transform: scale(0); opacity: 0; }
      }
    }
  }

  &.activate3 {
    &>button {
      &.btn3 { transform: scale(1.1); font-weight: 400; }
      &:not(.btn3) { transform: scale(1); font-weight: 200; }
    }
    .capsule {
      li {
        &.slide3 { transform: scale(1); opacity: 1; transition-delay: 0.2s; }
        &:not(.slide3) { transform: scale(0); opacity: 0; }
      }
    }
  }

  &.activate4 {
    &>button {
      &.btn4 { transform: scale(1.1); font-weight: 400; }
      &:not(.btn4) { transform: scale(1); font-weight: 200; }
    }
    .capsule {
      li {
        &.slide4 { transform: scale(1); opacity: 1; transition-delay: 0.2s; }
        &:not(.slide4) { transform: scale(0); opacity: 0; }
      }
    }
  }

  &.activate5 {
    &>button {
      &.btn5 { transform: scale(1.1); font-weight: 400; }
      &:not(.btn5) { transform: scale(1); font-weight: 200; }
    }
    .capsule {
      li {
        &.slide5 { transform: scale(1); opacity: 1; transition-delay: 0.2s; }
        &:not(.slide5) { transform: scale(0); opacity: 0; }
      }
    }
  }

  .capsule {
    position: relative;
    background-image: url("../../assets/img/how-to/circulo.svg");
    background-repeat: no-repeat;
    width: 525px;
    height: 543px;
    margin: 25px auto;

    li {
      position: absolute;
      width: 370px;
      height: 370px;
      left: 50%;
      top: 50%;
      margin-top: -185px;
      margin-left: -185px;
      border-radius: 50%;
      background-color: $yellow;
      padding: 0 40px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      transform-origin: center;
      transform: scale(0);
      opacity: 0;
      transition: all 0.3s ease-out;

      &.slide1 {
        transform: scale(1);
        opacity: 1;
      }

      .p2 {
        display: none;
      }

      .step2 {
        .p1 {
          display: none;
        }

        .p2 {
          display: block;
        }
      }

      button {
        font-size: 16px;
        font-weight: 600;
        color: #0645ad;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        img {
          margin: 0 8px;
          width: 16px;
          height: auto;
        }

        &.prev {
          img {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .bg {
    pointer-events: none;
    position: absolute;
    top: 40px;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;

      &:nth-child(1) { margin-top: 185px; margin-left: -555px; }
      &:nth-child(2) { margin-top: 166px; margin-left: -646px; }
      &:nth-child(3) { margin-top: 360px; margin-left: -530px; }
      &:nth-child(4) { margin-top: 339px; margin-left: -377px; }
      &:nth-child(5) { margin-top: 428px; margin-left: -715px; }
      &:nth-child(6) { margin-top: 461px; margin-left: -714px; }
      &:nth-child(7) { margin-top: 529px; margin-left: -490px; }
      &:nth-child(8) { margin-top: 581px; margin-left: -614px; }
      &:nth-child(9) { margin-top: 175px; margin-left: 400px; }
      &:nth-child(10) { margin-top: 140px; margin-left: 476px; }
      &:nth-child(11) { margin-top: 260px; margin-left: 344px; }
      &:nth-child(12) { margin-top: 378px; margin-left: 340px; }
      &:nth-child(13) { margin-top: 493px; margin-left: 317px; }
      &:nth-child(14) { margin-top: 530px; margin-left: 282px; }
      &:nth-child(15) { margin-top: 580px; margin-left: 450px; }
    }

    .left {
      position: relative;
    }
  }

  &.no-capsule {
    height: auto;
    padding-top: 20px;
    padding-bottom: 30px;

    h3 {
      width: 160px;
      font-size: 38px;
      margin-bottom: 130px;

      span {
        font-size: 22px;
      }
    }

    .container {
      &>div {
        margin-bottom: 80px;
      }
    }

    h4 {
      font-size: 30px;
      font-weight: 600;
      line-height: 38px;
      letter-spacing: -0.008em;
      text-align: left;
      color: $yellow;
    }

    p {
      text-align: left;
      color: #ffffff;
    }

    .bg {
      img {
        &:nth-child(1) { display: none; }
        &:nth-child(2) { width: 32vw; height: auto; margin-top: 60px; margin-left: -40vw; }
        &:nth-child(3) { display: none; }
        &:nth-child(4) { display: none; }
        &:nth-child(5) { display: none; }
        &:nth-child(6) { width: 60vw; height: auto; margin-top: 925px; margin-left: -50vw; }
        &:nth-child(7) { width: 25vw; margin-top: 1170px; margin-left: 5vw; }
        &:nth-child(8) { display: none; }
        &:nth-child(9) { display: none; }
        &:nth-child(10) { display: none; }
        &:nth-child(11) { width: 18vw; margin-top: 580px; margin-left: 15vw; }
        &:nth-child(12) { display: none; }
        &:nth-child(13) { width: 50vw; height: auto; margin-top: 340px; margin-left: 0; left: unset; right: 0; }
        &:nth-child(14) { width: 60vw; margin-top: 370px; margin-left: 0; left: unset; right: 0; }
        //&:nth-child(15) { margin-top: 580px; margin-left: 0px; }
      }
    }
  }
}

@media only screen and (max-width:400px) {
  .how-to {
    &.no-capsule {
      .bg {
        img {
          &:nth-child(2) { margin-top: 90px; }
          &:nth-child(11) { margin-top: 630px; }
          &:nth-child(6) { margin-top: 990px; }
          &:nth-child(7) { margin-top: 1280px; }
          &:nth-child(13) { margin-top: 350px; }
          &:nth-child(14) { margin-top: 380px; }
        }
      }
    }
  }
}

@media only screen and (max-width:370px) {
  .how-to {
    &.no-capsule {
      p {
        font-size: 16px;
      }
      .bg {
        img {
          &:nth-child(2) { margin-top: 60px; }
          &:nth-child(11) { margin-top: 620px; }
          &:nth-child(6) { margin-top: 970px; }
          &:nth-child(7) { margin-top: 1240px; }
          &:nth-child(13) { margin-top: 350px; }
          &:nth-child(14) { margin-top: 380px; }
        }
      }
    }
  }
}