#general-loading {
  display: flex;
  align-content: center;
  justify-content: center;
  position: fixed;
  padding-top: 40vh;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.55);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;

  svg {
    display: block;
    width: 60px;
    height: 60px;
  }

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.solid {
    background-color: #808080;
  }

  @supports (-ms-ime-align:auto) {
    background-color: rgba(255,255,255,0.85);
    svg {
      margin-top: 40vh;
    }
  }
}