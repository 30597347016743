@import '../../styles/base/vars';

.assessment-report {
  header {
    max-width: 865px;
    margin: 0 auto;
    padding: 80px 0 5px;

    h3 {
      display: block;
      // background: url("../../assets/img/bg-title.svg") no-repeat center;
      width: 436px;
      height: 100px;
      padding: 12px 25px;
      color: $yellow;
      margin: 0 auto 20px;
      transform: rotate(-2deg);
    }

    p {
      text-align: center;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  .list-report {
    padding: 0 5%;
  }
}

@media only screen and (max-width:990px) {
  .assessment-report {
    header {
      padding-top: 40px;

      h3 {
        padding-top: 20px;
        font-size: 34px;
        width: 340px;
        background-size: contain;
        line-height: 30px;

        span {
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        text-align: left;
        padding-right: 30px;
      }
    }

    .list-report {
      padding: 0 5%;
    }
  }
}

@media only screen and (max-width:475px) {
  .assessment-report {
    header {

      h3 {
        padding-top: 20px;
        font-size: 34px;
        line-height: 30px;

        span {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}

@media only screen and (max-width:400px) {
  .assessment-report {
    header {

      h3 {
        width: 100%;
        padding-top: 20px;
        font-size: 30px;
        line-height: 30px;

        span {
          font-size: 20px;
          line-height: 24px;
        }
      }
    }
  }
}