.login-container-wrapper {
    background-image: linear-gradient(130deg, #d8371c, #982b44, #541e6f);
    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.login-container-wrapper:after {
    content: "";
    background-image: url(/src/assets/images/bg-auth.png);
    background-repeat: no-repeat;
    position: absolute;
    width: 760px;
    height: 496px;
    top: 0;
    z-index: -1;
    left: 0;
}

.login-container {
    max-width: 1168px;
    margin: 0 auto;
    min-height: 364px;
    padding: 0;
    position: relative;
}

@media (min-width: 992px) {
    .login-container {
        height: calc(100vh - 160px);
    }
}

@media (max-width: 600px) {
    .login-container {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.login-container-content {
    position: relative;
    max-width: 500px;
    padding: 40px 60px 10px;
    margin: 0 auto;
    background: #fff;
}

@media (min-width: 992px) {
    .login-container-content {
        top: 50%;
        transform: translateY(-50%);
    }
}

.login-container-content label {
    color: rgba(0, 0, 0, .65);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 700;
}

.login-container-content .logo-wrapper {
    margin-bottom: 20px;
    text-align: center;
}

.login-container-content button {
    border-radius: 5px 5px 5px 5px!important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}