.container-bar {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 16px;
}

.text-info {
  font-family: "PT Sans";
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
}

.percentage-text {
  font-family: "PT Sans";
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
}

.percentage-bar {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.bar {
  height: 16px;
  width: 100%;
  border-radius: 4px;
}

.bar-partition1 {
  height: 100%;
  border-radius: 4px 0px 0px 4px;
}

.bar-partition2 {
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}
