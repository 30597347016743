footer {
    z-index: 10;
    background: #273034 !important;
}

.footer-container {
    max-width: 1168px;
    margin: 0 auto;
    padding: 20px 0 15px;
    min-height: 160px;
    z-index: 10;
    -webkit-font-smoothing: antialiased;
}

.footer-container,
.footer-container a {
    color: #c6c6c6 !important;
}

dl,
ol,
ul {
    margin-top: 0;
    margin-bottom: 1em;
}

ol,
ul {
    list-style: none;
}

.footer-container .footer-links {
    font-size: 11px;
}

@media (max-width: 992px) {
    .footer-container {
        height: auto;
        padding: 20px 0 15px;
        text-align: center;
    }

    .footer-container .footer-links {
        padding: 30px 0;
    }
}

.footer-container .footer-logo {
    margin-top: 28px;
}

@media (max-width: 576px) {
    .footer-container .footer-logo {
        margin-bottom: 28px;
        margin-top: 3px;
    }
}

.footer-container-right-block {
    text-align: right;
}

@media (max-width: 992px) {
    .footer-container-right-block {
        text-align: center;
    }
}

.footer-container .logo-falconi-title {
    display: block;
    font-size: 11px;
    margin-bottom: 4px;
    margin-right: 5px;
}

.footer-container .footer-copy {
    font-size: 12px;
    margin: 0;
    line-height: 14px;
}