@import '../../styles/base/vars';

.banner {
  position: relative;
  background: url("../../assets/img/bg-estrelas.svg") no-repeat center top;
  background-size: cover;
  color: #ffffff;
  padding-top: 80px;
  height: 860px;
  margin-top: -1px;
  overflow: hidden;
  z-index: 90;

  h1 {
    margin: 0 0 10px;
    font-weight: 400;
    font-size: 56px;
    text-align: center;
    line-height: 65px;
    letter-spacing: 22px;
    text-transform: uppercase;    

  }

  p {
    max-width: 670px;
    margin: 0 auto 30px;
    font-weight: 400;
    font-size: 16px;
    line-height: 18.5px;
    text-align: center;
  }

  .container {
    z-index: 20 !important;
  }

  .image {
    transform-origin: center;
    position: relative;
    margin-top: -120px;
    margin-bottom: 150px;
    z-index: 90;

    .face {
      width: 124px;
      height: 124px;
      border-radius: 50%;
      position: absolute;
      top: 40px;
      left: 50%;
      margin-left: -64px;
      overflow: hidden;
      z-index: 20;
    }

    .glass {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center top;
      position: absolute;
      top: 0;      
      
      img {
        display: block;
        z-index: 999;        
      }
    }

    &>img {
      display: block;
      margin: 0 auto;
      transform: translateX(-95px);
    }
  }

  .name {

    position: relative;
    z-index: 200;

    p {
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 700;
      letter-spacing: -1px;
      z-index: 999;
      margin-top: -12px;
    }
  }

  h3 {
    font-size: 16;
    font-weight: 700;
    letter-spacing: -1px; 
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 80px;
    color: #ffffff;
  }

  h2 {
    margin: -15px 0 10px 0;
    width: 100%;
    text-align: center;
    color: $yellow;
    font-weight: 700;
    font-size: 32px;
    letter-spacing: -1px;
    z-index: 50;
  }

  .ship {
    right: 50%;
    margin-right: -22.5vw;
    top: 50%;
    margin-top: -1.1vw;
    position: absolute;
    display: block;
    z-index: 35;
    max-width: 290px;
    width: 15vw;
    height: auto;
  }

  .shadow {
    right: 50%;
    margin-right: -27.2vw;
    top: 50%;
    margin-top: 9.9vw;
    position: absolute;
    display: block;
    z-index: 35;
    width: 19vw;
    max-width: 368px;
    height: auto;
    mix-blend-mode: multiply;
  }

  .bg-ground-rock {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url("../../assets/img/bg-pedras.svg") no-repeat center center;
    background-size: cover;
    z-index: 35;
    display: block;
    max-width: 2000px;
    transition: all .5s linear;
  }

  .bg-ground {
    position: absolute;
    top: 0;
    left: -3vw;
    right: 0;
    bottom: 0;
    background: url("../../assets/img/bg-solo.svg") no-repeat center;
    background-size: cover;
    z-index: 25;
    width: 103vw;
    max-width: 2000px;
    display: block;
    transition: all .5s linear;
  }

  .bg-montain-1 {
    position: absolute;
    top: 0;
    left: -5vw;
    right: 0;
    bottom: 0;
    background: url("../../assets/img/bg-montanhas-primarias.svg") no-repeat center;
    background-size: cover;
    z-index: 30;
    display: block;
    width: 105vw;
    max-width: 2050px;
    transition: all .5s linear;
  }

  .bg-montain-2 {
    position: absolute;
    top: 0;
    left: -5vw;
    right: 0;
    bottom: 0;
    background: url("../../assets/img/bg-montanhas-secundarias.svg") no-repeat center;
    background-size: cover;
    z-index: 10;
    width: 105vw;
    max-width: 2050px;
    transition: all .5s linear;
  }

  .moon {
    position: absolute;
    top: 150px;
    left: 0;
    width: 90vw;
    height: 90vw;
    border-radius: 50%;
    transform-origin: center;
    margin-top: 50px;
    transform: rotate(-8deg);
    opacity: 0;
    transition: all .5s linear;

    img {
      display: block;
      position:absolute;
      width: 10vw;
      height: auto;
      transform: translateX(200px);
      transition: all .5s linear;
    }
  }
}

@media only screen and (min-width:1920px) {
  .banner {
    .ship {
      margin-right: -435px;
      margin-top: -18px;
    }
    .shadow {
      margin-right: -525px;
      margin-top: 195px;
    }
  }
}

@media only screen and (max-width:990px) {
  .banner {
    padding-top: 110px;
    height: 900px;

    h1 {
      font-size: 30px;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: 0.4em;
      margin-bottom: 15px;
    }

    p {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.02em;
      padding: 0 20px;

      br {
        display: none;
      }
    }

    .image {
      margin-top: -130px;

      .face {
        left: unset;
        top: 9vw;
        right: 11.5vw;
        width: 28vw;
        height: 28vw;

        .glass {
          width: 28vw;
          height: 28vw;

          img {
            width: auto;
            height: 100%;
          }
        }
      }

      &>img {
        width: 90vw;
        margin: 0 auto;
        transform: translateX(0);
      }
    }

    .moon {
      top: 250px;
      width: 110vw;

      img {
        width: 20vw;
      }
    }

    .bg-ground, .bg-montain-1, .bg-montain-2 {
      width: 130vw;
      left: -15vw;
    }

    h2 {
      margin-bottom: 5px;
      font-size: 28px;
      line-height: 32px;
      letter-spacing: -0.02em;
    }

    .name {
      p {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
}

@media only screen and (max-width:375px) {
  .banner {
    h1 {
      font-size: 8vw;
    }
  }
}

.backButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 9px 16px;
  width: fit-content;
  height: 48px;
  border: none;
  cursor: pointer !important;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  box-shadow: none;
  background-color: #ff4d0d;
  color: #ffffff;

}

.backButton:hover {
  background-color: #ee4c15;
}

.backButton:focus {
  border: 2px solid #ee4c15;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

.backButton:active {
  background: #D14211;
}