.assessment-progress-bar-wrapper {
    width: 100%;
    margin: 30px 0 10px;
}

.assessment-progress-bar {
    width: 100%;
    height: 10px;
    background: #ddd;
    position: relative;
}

.bar-phase {
    float: left;
    height: 100%;
}

.bar-phase-green {
    width: 50%;
    background: #00d100;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.bar-phase-yellow {
    width: 34%;
    background: #ee4;
}

.bar-phase-red {
    width: 16%;
    background: #bb0303;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.triangle-marker {
    position: absolute;
    top: -50%;
    left: 0;
    height: 150%;
    background: hsla(0, 0%, 51.4%, .50);
    max-width: 100%;
}

.triangle-marker:before {
    top: -6px;
    right: -14px;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border: 10px solid transparent;
    border-bottom-color: #f35d2f;
    margin-left: -15px;
    transform: rotate(180deg);
}