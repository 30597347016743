.menu-area {
    z-index: 1000;
    width: 100%;
    height: 60px;
    background: #293d41;
    position: fixed;
    color: white;
    top: 0;
}
.logo-menu {
    width: 70px;
    cursor: pointer;
    margin-top: 20px;
}
.list-menu-area {
    margin-top: 15px;
    width : 40px;
    cursor: pointer;
    margin-left: 1.5em;
}
.link-area {
    height: 60px;
    padding: 20px;
    width: 100%;
    display: inline-flex;
}
.link-area-menu {
    height: 60px;
    padding: 20px;
    display: inline-flex;
}
.link-area:hover {
    height: 60px;
    padding: 20px;
    display: inline-flex;
    background: #f35e2f;
}
.link-area-menu:hover {
    height: 60px;
    padding: 20px;
    display: inline-flex;
    background: #f35e2f;
}

.link-area-active {
    height: 60px;
    padding: 20px;
    background: #f35e2f;
    display: inline-flex;
}
.link {
    color: white;
    cursor: pointer;
    font-size: 0.7rem;
}
.submenu-area {
    margin-top: 0;
    width: 100%;    
    height: 100%;
    background: #293d41;
    color: white;
}
.submenu-area-language {
    margin-top: 0;
    width: 52px;
    height: 100%;
    background-image: linear-gradient(#293d41, #474b4f);
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    color: white;
    margin-left: 92px;
}
.link-area-submenu-language {
    text-align: left !important;
    height: 50px;
    padding: 12px;
}
.submenu-area-links {
    margin-top: 5px;
    width: 170px;
    background: #7E8A8D;
    color: white;
    z-index: 1000;
    position: fixed;
    top: 65px;
}
.link-area-submenu-language:hover {
    text-align: left !important;
    height: 50px;
    padding: 12px;
    border-bottom-left-radius: 10px; 
    border-bottom-right-radius: 10px; 
    background: #f35e2f;    
}
.link-area-submenu {
    text-align: left !important;
    height: 50px;
    padding: 13px;
    border-bottom: 1px solid #3e5054;
}
.link-area-submenu:hover {
    text-align: left !important;
    height: 50px;
    padding: 13px;
    background: #f35e2f;    
}

.link-area-language {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 8px;
    height: 60px;
}
/* .link-area-language:hover {
    height: 60px;
    display: inline-flex;
    background: #f35e2f;
} */
.link-area-active-language {
    height: 60px;
    background: #f35e2f;
    display: inline-flex;
}

.menu-mobile {
    z-index: 1000;
    width: 100%;
    position: fixed;
    padding: 10px;
}
.menu-mobile-area {
    background: #293d41;
    width: 100%;
}