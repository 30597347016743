body {
    margin: 0;
    color: rgba(0, 0, 0, .65);
    font-size: 15px;
    font-family: 'PT Sans', calibri, 'lucida grande', sans-serif;
    font-variant: tabular-nums;
    line-height: 1.4;
    background-color: #fff;
    font-feature-settings: "tnum";
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5em;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
}

h1 {
    /* text-transform: uppercase; */
    font-weight: 700;
}

.component-area {
    margin-top: -2px;
    background-image: url("../images/bgimage.png");
    object-fit: cover;
    width: 100%;
    height: 300px;
    color: white;
}

.component-area-text {
    padding: 50px;
    color: #fff !important;
}

.component-area-img-top {
    width: 100%;
}

.icon-table {
    color: #f35e2f;
    cursor: pointer;
    font-size: 1.1rem;
}

.button {
    color: #fff;
    background: #f35e2f;
    border: none;
    outline: 0;
}

.button:hover {
    color: #fff;
    background: #f35e2f;
    border: none;
    outline: 0;
}

.button:focus {
    color: #fff;
    background: #f35e2f;
    border: none;
    outline: 0;
}

input {
    border: 1px solid #434343;
    box-shadow: 0;
}

input:hover {
    border: 1px solid #434343;
    box-shadow: 0;
}

input:hover {
    border: 1px solid #434343;
    box-shadow: 0;
}

li.ant-menu-item.menu-item {
    margin-left: 30px !important;
}

ul.ant-menu.ant-menu-sub.ant-menu-vertical {
    background: #293d41 !important;
}

.menu-global {
    background: #293d41;
}

.footer {
    background: #273034;
    padding: 80px;
}

.logo-menu {
    width: 70px;
    margin-left: 100px;
}

.submenu {
    background: #293d41;
    /* padding: 50px; */
}

.menu-item {
    color: #c3c8ca;
    font-size: 0.7rem;
    font-weight: 600;
}

.menu-item:before {
    background: red !important;
}

.menu-item:hover {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
}

.menu-item:focus {
    color: #fff;
    font-size: 0.7rem;
    font-weight: 600;
}

.footer-item {
    /* margin-top: 10px; */
    color: #c3c8ca;
    font-size: 0.7rem;
    font-weight: 200;
}

.footer-item:hover {
    /* margin-top: 10px; */
    color: #fff;
    font-size: 0.7rem;
    font-weight: 200;
    cursor: pointer;
}

.footer-item-right-area {
    text-align: right;
}

.footer-item-right-title {
    color: #c3c8ca;
    font-size: 1rem;
    font-weight: 200;
}

.footer-item-right {
    margin-top: 8px;
    color: #c3c8ca;
    font-size: 0.7rem;
    font-weight: 200;
}

.img-footer-frst {
    margin-top: 30px;
}

.item-second {
    margin-top: 20px;
}

/* .user-profile {
    text-align: right;
} */
.user-config {
    color: #c3c8ca;
    font-size: 1rem;
    font-weight: 200;
    margin-left: 10px;
}



.register-user-top {
    background: #e6e6e6;
    height: 100%;
}

.register-user-top-text {
    padding: 50px;
}

.register-user-licenses {
    font-size: 0.9rem;
    font-weight: bold;
    padding: 50px;
}

.register-user-table {
    padding: 50px;
}

.register-user-button {
    padding: 50px;
}

.register-user-table-email-area {
    color: rgba(0, 0, 0, .65);
}

.register-user-table-email {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, .65);
}

.register-user-table-group {
    font-size: 0.7rem;
    color: rgba(0, 0, 0, .65);
}

.register-user-table-assessment {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, .65);
}

.register-user-table-assessment-status {
    font-size: 0.8rem;
    color: #f5740e;
}

.register-user-table-reassessment {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, .65);
}

.register-user-table-reassessment-status {
    font-size: 0.8rem;
    color: #f5740e;
}

.register-user-table-enrollment-status {
    font-size: 0.8rem;
    color: #f5740e;
}

.register-user-table-status {
    font-size: 0.8rem;
    color: #f5740e;
}

.button-no-block {
    background: #f35e2f;
    color: #fff;
    border: none;
    outline: 0;
}

.button-no-block:hover {
    background: #f35e2f;
    color: #fff;
    border: none;
    outline: 0;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.button-no-block:focus {
    background: #f35e2f;
    color: #fff;
    border: none;
    outline: 0;
}

@media (max-width: 575.98px) {
    .register-area {
        background-image: url("../images/bgimage.png");
        object-fit: cover;
        height: 100%;

    }

    .register-text-area {
        padding: 100px;
        color: white;
        margin-top: 10%;
    }

    .register-text-title {
        color: white;
        font-weight: bold;
    }

    .register-card-area {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .register-card {
        width: 90%;
        margin-left: 5%;
        height: 100%;
        background: white;
        border-radius: 5px;
        padding: 40px;
    }

    .register-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }

    .register-form {
        margin-top: 30px;
    }

    .register-input {
        margin-top: 15px;
    }

    .register-button {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .register-button:hover {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .register-area {
        background-image: url("../images/bgimage.png");
        object-fit: cover;
        height: 100%;

    }

    .register-text-area {
        padding: 100px;
        color: white;
        margin-top: 10%;
    }

    .register-text-title {
        color: white;
        font-weight: bold;
    }

    .register-card-area {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .register-card {
        width: 80%;
        margin-left: 10%;
        height: 100%;
        background: white;
        border-radius: 5px;
        padding: 60px;
    }

    .register-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }

    .register-form {
        margin-top: 30px;
    }

    .register-input {
        margin-top: 15px;
    }

    .register-button {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .register-button:hover {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .register-area {
        background-image: url("../images/bgimage.png");
        object-fit: cover;
        height: 100%;

    }

    .register-text-area {
        padding: 80px;
        color: white;
        margin-top: 20%;
    }

    .register-text-title {
        color: white;
        font-weight: bold;
    }

    .register-card-area {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    .register-card {
        width: 90%;
        margin-left: 5%;
        height: 100%;
        background: white;
        border-radius: 5px;
        padding: 30px;
    }

    .register-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }

    .register-form {
        margin-top: 30px;
    }

    .register-input {
        margin-top: 15px;
    }

    .register-button {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .register-button:hover {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .register-area {
        background-image: url("../images/bgimage.png");
        object-fit: cover;
        height: 100%;

    }

    .register-text-area {
        padding: 80px;
        color: white;
        margin-top: 20%;
    }

    .register-text-title {
        color: white;
        font-weight: bold;
    }

    .register-card-area {
        margin-top: 70px;
        margin-bottom: 70px;
    }

    .register-card {
        width: 90%;
        margin-left: 5%;
        height: 100%;
        background: white;
        border-radius: 5px;
        padding: 40px;
    }

    .register-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40%;
    }

    .register-form {
        margin-top: 30px;
    }

    .register-input {
        margin-top: 15px;
    }

    .register-button {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .register-button:hover {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

}

@media (min-width: 1200px) {
    .register-area {
        background-image: url("../images/bgimage.png");
        object-fit: cover;
        height: 100%;

    }

    .register-text-area {
        padding: 100px;
        color: white;
        margin-top: 10%;
    }

    .register-text-title {
        color: white;
        font-weight: bold;
    }

    .register-card-area {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .register-card {
        width: 80%;
        margin-left: 10%;
        height: 100%;
        background: white;
        border-radius: 5px;
        padding: 50px;
    }

    .register-image {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 30%;
    }

    .register-form {
        margin-top: 30px;
    }

    .register-input {
        margin-top: 15px;
    }

    .register-button {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .register-button:hover {
        font-size: 0.9rem;
        width: 100%;
        margin-top: 30px;
        color: white;
        background: #f5740e;
        border: none;
        box-shadow: 0;
    }

    .container-page {
        padding: 0 50px;
    }
}

div.ant-menu-submenu-title,
div.ant-menu-submenu-title:hover,
div.ant-menu-submenu-title:focus {
    color: #fff !important;
    margin: 0 30px 0 30px !important;
    border: none !important;
    text-decoration: none !important;
}

li.ant-menu-item.ant-menu-item-only-child.menu-item {
    margin: 0 30px 8px 30px !important;
    border: none !important;
    text-decoration: none !important;
    color: #c3c8ca;
}

li.ant-menu-item.ant-menu-item-only-child.menu-item:hover {
    margin: 0 30px 8px 30px !important;
    border: none !important;
    text-decoration: none !important;
    color: #fff;
}

li.ant-menu-item.ant-menu-item-only-child.menu-item:focus {
    margin: 0 30px 8px 30px !important;
    background: #f5740e;
    border: none !important;
    text-decoration: none !important;
    color: #c3c8ca;
}

.site-layout {
    margin: 0;
    padding-top: 64px;
}

.ant-btn-primary {
    color: #fff;
    background: #f5740e;
    border-color: #f5740e;
}

.ant-btn-primary:active {
    color: #fff;
    background: #f5740e;
    border-color: #f5740e;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
    color: #fff;
    background: #f5740e;
    border-color: #f5740e;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background: #f5740e;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
    background: #f5740e;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f5740e;
}

.global-title-h1,

.global-title-h5 {
    font-size: 32px;
    line-height: 1;
    color: #2a3d41;
    margin-bottom: 15px;
    font-family: 'Work Sans', sans-serif;
}

.global-title-h5 {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 7px;
}

a {
    color: #f35e2f;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
}

a:active,
a:hover {
    text-decoration: none;
    outline: 0;
}

a:active {
    color: #cc401d;
}

a:hover {
    color: #ff8559
}

.ant-btn {
    line-height: 1.399;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    cursor: pointer;
    transition: all .3s cubic-bezier(.645, .045, .355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 15px;
    border-radius: 5px 5px 5px 5px !important;
    color: #fff;
    background-color: #2a3d41;
    border: 1px solid #2a3d41;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
    outline: 0;
}

.ant-btn:focus,
.ant-btn:hover {
    color: #ff8559;
    background-color: #2a3d41;
    border-color: #ff8559;
}

.ant-btn.active,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
    text-decoration: none;
    background: #2a3d41;
}

.ant-btn:not([disabled]):hover {
    text-decoration: none;
}

.ant-btn-primary {
    color: #fff;
    background-color: #f35e2f;
    border-color: #f35e2f;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
    color: #fff;
    background-color: #ff8559;
    border-color: #ff8559;
}

a.ant-btn {
    padding-top: .1px;
    line-height: 30px;
}

.ant-btn-link {
    color: #f35e2f;
    box-shadow: none;
    border: none;
    background-color: transparent;
}

.ant-btn-link:active,
.ant-btn-link:focus {
    color: #cc401d;
    background-color: transparent;
}

.ant-btn-link:hover {
    color: #ff8559;
    background-color: transparent;
}

.btn-normal {
    border-radius: 3px !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    font-size: 15px;
    font-weight: 400;
    height: 32px;
    padding: 0 15px;
}

.btn-big {
    border-radius: 5px 5px 5px 5px !important;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    height: 60px;
    padding: 10px 30px 13px;
}

.btn-black {
    color: #fff;
    background-color: #2a3d41;
    border-color: #2a3d41;
}

.btn-orange {
    color: #fff;
    font-weight: bold;
    background-color: #F35F24;
    border-color: #F35F24;
    height: 48px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    text-transform: none;
    width: auto;
}

.btn-link {
    width: 150px;
    text-align: left;
    margin-left: 8px;
    color: #0645AD;
    font-weight: bold;
}

.btn-orange:focus,
.btn-orange:hover {
    color: #fff;
    background-color: #E94E1B;
    border-color: #fff;
}


.btn-black:focus,
.btn-black:hover {
    color: #ff8559;
    background-color: #2a3d41;
    border-color: #ff8559;
}

.btn-black.active,
.btn-black:active {
    color: #cc401d;
    background-color: #2a3d41;
    border-color: #cc401d;
}

.btn-download,
.btn-download.active,
.btn-download:active,
.btn-download:focus,
.btn-download:hover {
    margin-right: 16px;
    padding-left: 48px;    
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: left 16px top 11px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAD4SURBVHgB3ZbhDYIwEIWvxgEcgQ1kAxnBDXQDR8AR3ABGkAlwBDaADZQJ6p22samS3pX+MLzkSNO8vq8NbQGAKa11htVjnUGglcCbmdoJxogAUYoBDBJzDGCUmGMAD4l5oQDc6yXWHpiAgP/LnOuPDk5/YfoKz1+Z/ha4otPqQxxA/iO8p5MOEvkQB5DNDp+A2MBNkvAJCOkoCkfTiQYxIXesOhSu3zfv5eWx0wLeSii4Dc3cWWW1BqaUUgTosNlhe+COYwMM5ApC/eX3IA5A+xvSyWaN9A5uWAVWhZAG5ovCS9Pu3L+F1KqJoOhh9jVdt1tIo8buuCctJYzkeEs4ygAAAABJRU5ErkJggg==") !important;
}

.btn-write,
.btn-write.active,
.btn-write:active,
.btn-write:focus,
.btn-write:hover {
    margin-right: 16px;
    padding-left: 48px;    
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: left 16px top 11px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFDSURBVHgBzZThUcMwDIUVjv9kBI8QNsgGdAOyAdkgZgLYgLJBN3CZoGUCd4N2AyFh5TA+N46d/Oi7e5fEz/6cSDkDrCBE3JANOlnyG7mGNUSgAeMysFQBvOe3Jiv5ClYLpZIyjOqCTMu4hhLRwg8BnLn+kfwQ23gOuA7gTSQ3XrNVLvzoLW4SucqBq6nFqXwO3Cbg9ubh/Pl1Tp6CN/KXXIP7uVkC30bydipPwTcJ+DP+6R1y5dVUJ+A6lz02jXWOZMMiuEA6AexWhwvICKSTRvfeGKuHUqE7R6bUQaHu5doE4yfynvxF3lVVdYFCVeONlIBBewKe/EnojgD2hbIj5IoAL1Lv3/rLWC3Ph6BclufnbmAxXxZnHG53cv1OzONf95X8Ca4/LEUeEuv+9YAXtOAa/uBtvPWbLPM0+Yn8GPYr1A+MI880gKweFAAAAABJRU5ErkJggg==") !important;
}

.btn-no-icon,
.btn-no-icon.active,
.btn-no-icon:active,
.btn-no-icon:focus,
.btn-no-icon:hover {
    padding-left: 16px;
    margin-right: 16px;
    
}

.btn-link:focus,
.btn-link:hover {
    color: #3366BB;
}

.btn-answer,
.btn-answer.active,
.btn-answer:active,
.btn-answer:focus,
.btn-answer:hover {
    padding-left: 48px;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: left 16px top 14px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADzSURBVHgB7VXRDYIwEH0a/nUERnAE3MBR3EDcwBXcQCcoToBOIBuIE9S78Bobgg1i+ZKXXK69JvcovHfAWptKGBsfRnvPdAEgwzgo4DEuEQnayzXVG1gtzgSICNd3jpExEQwnoBJ2Ehvu1S95xz5FCE5OHfXcl7DEgesHzw33Zahv6BUVErVmUbDmK+tH5jPzJdADkw/+gCBBoxSVYS65wndQhVX0QubV3WSu9Wtv7XDcabjsw3meyBOogfQWK4kF+iOTSCX0h7VnrULjnydvd8JQ0N2lczezQUy0SDoJflIRR8ga7zGCqAR9SKL4wCO5tc9ex1eDITkn37kAAAAASUVORK5CYII=") !important;
}

/* @media only screen and (max-width: 992px) {
    .visibleMobileBlock {
        display: block;
    }

    .visibleDesktopInlineBlock {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    .visibleMobileBlock {
        display: none;
    }

    .visibleDesktopInlineBlock {
        display: inline-block;
    }
} */

.ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 8px !important;
    box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
    pointer-events: auto;
}

.ant-modal-header {
    padding: 16px 24px;
    background-color: #293d41;
    color: #fff !important;
    border-radius: 5px 5px 0 0;
}

.ant-modal-title {
    font-family: "PT Sans", calibri, "lucida grande", sans-serif;
    margin: 0;
    font-size: 17px;
    line-height: 22px;
    word-wrap: break-word;
    background-color: #293d41 !important;
    color: #fff !important;
    font-weight: 900 !important;
}

.ant-modal-close-x {
    color: #fff !important;
    font-weight: 900 !important;
}

.main-wrapper {
    min-height: calc(100vh - 160px);
    margin: 0px;
    padding: 50px;
    background-color: #fff;
}

.bg-image {
    background-image: url(/src/assets/images/bg-logo.png);
    background-repeat: no-repeat;
    background-position: right 10% top 0;
}

.main-wrapper .main-container {
    max-width: 1200px;
    margin: 0px auto;
}

.main-wrapper .main-container-inner {
    padding-right: 2rem;
    padding-left: 2rem;
}

.main-container-success {
    text-align: center;
}

.main-wrapper .title {
    font-size: 40px;
    font-weight: "bold";
    line-height: 1;
    color: #2a3d41;
    margin: 10px 0;
    font-family: 'Work Sans', sans-serif;
}

.img-video-assessment {
    width: 620px;
    max-width: 95vw;
    cursor: pointer;
    box-shadow: 1px 3px 6px 0 rgb(0 0 0 / 80%);
}

.img-video-assessment-wrapper {
    text-align: center;
    position: relative;
    margin-top: 40px;
}

.img-arrow-right-white {
    position: absolute;
    cursor: pointer;
    left: calc(50% - 25px);
    top: calc(50% - 30px);
}

.img-how-works {
    max-width: 100%;
    /* box-shadow: 1px 3px 6px 0 rgb(0 0 0 / 80%); */
    cursor: pointer;
}

.img-play-button {
    position: absolute;
    cursor: pointer;
    left: 50%;
    top: 38%;
}

.content-container {
    padding: 0 ; 
}

.content-container {
    padding: 30px
}

.assessment-progress-bar-content {
    width: 40%;
}

.question-text-button {
    width: auto;
    float: none;
    text-align: center;
    margin-top: 20px;
}

.question-text-button-finish {
    float: right;
    margin-top: 20px;
}

.question-text-button-link {
    margin-top: 30px;
    text-align: center;
}

@media only screen and (max-width: 992px) {
    .main-wrapper {
        padding: 0 !important;   
    }

    .main-container {
        padding: 0 !important; 
    }

    .main-container-inner{
        padding: 0 !important; 
    }

    .content-container {
        padding: 2em !important; 
    }

    .row-dash-video {
        padding: 0 !important;
    }

    .content-question-text {
       width: "100%" !important; 
    }

    .content-image {
        padding: 0;
        margin-top: -5px;
    }

    .btn-no-icon,
    .btn-no-icon.active,
    .btn-no-icon:active,
    .btn-no-icon:focus,
    .btn-no-icon:hover {
        width: 100%; 
        margin-top: 1em;
    }

    .btn-write,
    .btn-write.active,
    .btn-write:active,
    .btn-write:focus,
    .btn-write:hover {
        width: 100%; 
        margin: 1em 0 0 0 !important;
        padding: 0 !important;
    }
    
    .btn-answer,
    .btn-answer.active,
    .btn-answer:active,
    .btn-answer:focus,
    .btn-answer:hover {        
        width: 100%; 
        margin: 1em 0 0 0 !important;
        padding: 0 !important;
    }    

    .footer-buttom {        
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center
    }

    .assessment-progress-bar-content {
        width: 100%;
    }

    .question-text-button {
        width: 100%;
        text-align: center;
        margin-top: 20px;
    }

    .question-text-button-finish {
        width: 100%;
        float: none;
        text-align: center;
        margin-top: 20px;
    }

    .question-question-button-finish {    
        text-align: center  !important;
        margin-top: 20px !important;
    }

    .question-text-button-link {
        margin-top: 20px !important;
        text-align: right;
        width: 100%;
    }
    .main-wrapper .title {
        font-size: 28px;
        font-weight: "bold";
        line-height: 1;
        color: #2a3d41;
        margin: 10px 0;
        font-family: 'Work Sans', sans-serif;
    }    

    .logo-menu {
        width: 70px;        
        margin-left: 100% !important; /* Ajuste para centralizar a logo da Frst no Header */
    }
}

.spanLink {
    color: #0645AD !important;
    font-size: 16px !important;
    font-weight: bolder !important;
    cursor: pointer;
  
    position: relative;
    /* margin-top: 16px; */
    margin-bottom: 16px;
  }
  
  .spanLink:disabled {
    color: #7C7C7C !important;; 
  }