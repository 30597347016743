.text-list-assessment {
    font-size: 16px;
    margin-left: 25px;
    text-align: left;
    margin-top: 15px;
}

.content-list-assessment {
    width: 90%;
    margin-top: 8px;
}