@import "../../../styles/base/vars";

.bar {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  .bar-container {
    width: 100%;
    margin: 8px 0;
    position: relative;
    height: 15px;
    border-radius: 100px;
    background-color: #a6a6a6;
    padding: 2px;

    .bar-value {
      position: relative;
      width: 100%;
      height: 13px;
      background-color: $yellow;
      border-radius: 100px;
      z-index: 10;
      transform-origin: left;
      transform: scaleX(0);
      transition: all 1s ease-out;
    }

    .bar-bg {
      position: absolute;
      top: 2px;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 100px;
      height: 13px;
      background-color: #e5e5e5;
    }
  }

  .bar-percent {
    width: 55px;
    text-align: right;
    font-size: 18px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #7c7c7c;
  }
}

@media only screen and (max-width: 990px) {
  .bar-container {
  }
}
